<template>
  <v-container>
    <v-btn
      color="primary"
      @click="openFileTrigger"
    >
      Open file
    </v-btn>
    <input
      :id="fileTriggerId"
      type="file"
      style="display: none;"
      @change="handleFileTriggerChange"
    >
    <v-btn
      :disabled="!possiblyMakeBulkLoad"
      :loading="productsBulkLoading"
      color="success"
      @click="bulkLoad"
    >
      Make bulk load
    </v-btn>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'

  export default {
    name: 'ProductsBulkLoad',
    data () {
      return {
        fileTriggerId: 'file-trigger',
        file: undefined,
      }
    },
    computed: {
      ...mapState([
        'productsBulkLoading',
      ]),
      fileTrigger () {
        return document.getElementById(this.fileTriggerId)
      },
      possiblyMakeBulkLoad () {
        return this.file !== undefined
      },
      fileFormData () {
        if (!this.possiblyMakeBulkLoad) {
          return undefined
        }
        const formData = new FormData()
        formData.append('file', this.file)

        return formData
      },
    },
    methods: {
      ...mapActions([
        'productsBulkLoad',
      ]),
      openFileTrigger () {
        this.fileTrigger.click()
      },
      handleFileTriggerChange (event) {
        const file = event.target.files[0]
        if (file) {
          this.file = file
        }
      },
      async bulkLoad () {
        if (this.possiblyMakeBulkLoad) {
          await this.productsBulkLoad(this.fileFormData)
        }
      },
    },
  }
</script>
